import React from "react";
import dayjs from "dayjs";
import { graphql } from "gatsby";

import { Layout } from "~components";
import { Img, Link, RawHtml } from "~utils";

/**
 * @typedef {{
 *  data: GatsbyTypes.PostQuery,
 *  pageContext: GatsbyTypes.SitePageContext,
 * }} Props
 *
 * @extends {React.Component<Props>}
 */
export default class Post extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { frontmatter } = data.page;
    const [githubUser, githubRepo] = frontmatter.github?.split("/") ?? [];
    const githubUrl = `https://github.com/${githubUser}/${githubRepo}`;
    const tweetId = frontmatter.tweet
      ? frontmatter.tweet.split("/status/")[1]
      : null;
    const image = frontmatter.content_img_path?.childImageSharp?.fluid;

    return (
      <Layout pageData={data}>
        <article className="post post-full">
          <header className="post-header">
            <h1 className="post-title underline">{frontmatter.title}</h1>
          </header>
          {frontmatter.subtitle && (
            <div className="post-subtitle">
              {<RawHtml html={frontmatter.subtitle} />}
            </div>
          )}
          {image && (
            <div className="post-thumbnail">
              <Img fluid={image} alt={frontmatter.title} />
            </div>
          )}
          <div className="post-content">
            {<RawHtml html={pageContext.html} />}
            {githubRepo ? (
              <>
                <h3>Source</h3>
                <p>You can find the source on Github:</p>
                <p>
                  <Link to={githubUrl}>{githubRepo}</Link>
                  &nbsp;
                  <span className="no-border">
                    <Link to={githubUrl}>
                      <Img
                        src={`https://img.shields.io/github/stars/${githubUser}/${githubRepo}?style=social`}
                      />
                    </Link>
                  </span>
                </p>
              </>
            ) : null}
          </div>
          <footer className="post-meta">
            {tweetId && (
              <div className="no-border">
                <Link
                  to={`https://twitter.com/intent/tweet?in_reply_to=${tweetId}`}
                >
                  <Img
                    src={`https://img.shields.io/badge/Discuss%20on%20Twitter--lightgrey?logo=twitter&style=social`}
                  />
                </Link>
                &nbsp;
                <Link to={frontmatter.tweet}>
                  <Img
                    src={`https://img.shields.io/badge/View%20Discussions--lightgrey?logo=twitter&style=social`}
                  />
                </Link>
              </div>
            )}
            {tweetId && <br />}

            <time
              className="published"
              dateTime={dayjs(frontmatter.date).format("YYYY-MM-DD HH:mm")}
            >
              {dayjs(frontmatter.date).format("MMMM DD, YYYY")}
            </time>
          </footer>
        </article>
      </Layout>
    );
  }
}

export const query = graphql`
  query Post($url: String!, $headerImg: String) {
    ...PageData
    page: markdownRemark(fields: { url: { eq: $url } }) {
      frontmatter {
        date
        tweet
        github
        content_img_path {
          childImageSharp {
            fluid(maxHeight: 450, quality: 100) {
              ...FluidImg
            }
          }
        }
      }
    }
  }
`;
